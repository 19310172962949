<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt @click="goBack" />
        <span class="center">权限管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">角色管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">{{ name }}</span>
      </div>
    </div>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in list"
          accordion
          :key="index"
          :label="item.name"
          :name="item.name"
        >
          <el-row :gutter="24">
            <el-col :span="8" v-for="item in radioList" :key="item.code">
              <div
                style="
                margin: 10px 10px 0 10px;
                display: flex;
                aligin-item: center;
              "
              >
                <div class="text-overflow label">
                  <i
                    class="el-icon-success"
                    style="color: #67c23a; padding: 0 10px"
                    v-if="item.scope"
                  ></i>
                  <i
                    class="el-icon-remove"
                    style="color: #f56c6c; padding: 0 10px"
                    v-if="!item.scope"
                  ></i>
                  <!-- <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.desc || item.name"
                  placement="top-start"
                  >-->
                  <span style="line-height: 26px">{{ item.name }}</span>
                  <!-- </el-tooltip> -->
                  <i
                    class="el-icon-warning"
                    style=" margin-left:10px;color:#3898f1"
                    @click="watchDetails(item)"
                  ></i>
                </div>
                <div>
                  <el-select
                    v-model="item.scopeCode"
                    placeholder="请选择"
                    :disabled="!item.custom || !item.enable"
                    @change="jurisdiction(item.code, item)"
                  >
                    <el-option style="color: red" :label="'禁用'" :value="null"></el-option>
                    <el-option :label="item.scopeList.length > 0 ? '全部权限' : '启用'" :value="'$all'"></el-option>
                    <el-option
                      v-for="(it, index) in item.scopeList"
                      :key="index"
                      :label="it.name"
                      :value="it.code"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-drawer title="详情" :visible.sync="drawer" direction="rtl" :before-close="handleClose">
      <div style="white-space: pre;padding:10px 20px">{{ desc }}</div>
    </el-drawer>
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
      :show-close="false"
    >
      <div slot="title" class="left_top">
        <div class="left_icon">
          <span></span>
          <span>{{ title }}</span>
        </div>
      </div>
      <el-table :data="despResources" border style="width: 100%">
        <el-table-column prop="objectName" label="所属模块"></el-table-column>

        <el-table-column label="资源">
          <template slot-scope="scope">
            <div
              v-if="title == '该依赖所包含的权限'"
              style="
                margin: 10px 10px 0 10px;
                display: flex;
                aligin-item: center;
              "
            >
              <div class="text-overflow label">
                <i
                  class="el-icon-success"
                  style="color: #67c23a; padding: 0 10px"
                  v-if="scope.row.scopeCode"
                ></i>
                <i
                  class="el-icon-remove"
                  style="color: #f56c6c; padding: 0 10px"
                  v-if="!scope.row.scopeCode"
                ></i>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.desc || scope.row.name"
                  placement="top-start"
                >
                  <span style="line-height: 26px">{{ scope.row.name }}</span>
                </el-tooltip>
              </div>
              <div>
                <el-select
                  v-model="scope.row.scopeCode"
                  placeholder="请选择"
                  @change="Subdependency(scope.row.scopeCode, scope.row.code)"
                >
                  <!-- @change="jurisdiction(item.name, item.code, item)" -->
                  <el-option style="color: red" :label="'禁用'" :value="null"></el-option>

                  <el-option :label="scope.row.scopeList > 0 ? '全部权限' : '启用'" :value="'$all'"></el-option>
                  <el-option
                    v-for="(it, index) in scope.row.scopeList"
                    :key="index"
                    :label="it.name"
                    :value="it.code"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <span v-else>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button type="primary" @click="submit()">确 定</el-button>
        <el-button @click="cancel()">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '/src/api/base';
//查看所有模块名称
export const authorizationList = api('')('/roleResource.objectnames.json');
//查看资源组列表
export const roleResource = api('')('/roleResource.list.json');
//根据角色和模块查询actions
export const roleObjectNameactionsList = api('')(
  '/role.objectNameactions.json'
);
///根据code获取依赖权限
export const roleResourceDependsList = api('')('/roleResource.depends.json');
//绑定
export const roleBindOne = api('')('role.bindOne.json');
//解绑
export const delRoleUnBindOne = api('')('role.unBindOne.json');

export default {
  data () {
    return {
      list: [],
      radioList: [],
      activeName: '',
      dialogVisible: false,
      title: '',
      code: '',
      name: '',
      usercode: '',
      relyList: [],
      List: [],
      string: '',
      scope: '',
      drawer: false,
      targetResource: null,
      despResources: [],
      desc: ''
    };
  },
  mounted () {
    this.code = this.$route.query.code;
    this.name = `授权给 [ ${this.$route.query.name} ] `;
    this.loadData();
  },
  methods: {
    //返回上一页
    goBack () {
      window.history.go(-1);
    },
    //依赖组下面LIST
    loadList (objectName) {
      const params = {
        roleCode: this.code,
        objectName: objectName,
        $ignoreRepeat: true
      };
      roleObjectNameactionsList(params).then(res => {
        this.radioList = res;
      });
    },

    watchDetails (item) {
      if (item.desc) {
        this.desc = item.desc;
      } else {
        this.desc = item.name;
      }
      this.drawer = true;
    },
    //依赖组Name
    loadData () {
      const params = {};
      authorizationList(params).then(res => {
        this.list = res.map(it => {
          return {
            name: it,
            radioList: []
          };
        });

        this.activeName = this.list[0].name;
        this.string = this.list[0].name;

        this.loadList(this.list[0].name);
      });
    },
    //弹出层下拉随时刷新方法
    Subdependency () { },
    //有依赖时候绑定
    roleResourceDepends () {
      const params = {
        code: this.usercode,
        roleCode: this.code
      };
      roleResourceDependsList(params).then(res => {
        if (res.length > 0) {
          this.despResources = res;
          this.title = '该依赖所包含的权限';
          this.dialogVisible = true;
          return;
        } else {
          this.dialogVisible = false;

          this.Bind();
        }
      });
    },
    //没依赖时候绑定
    Bind () {
      const result = [this.targetResource, ...this.despResources].map(it => {
        return {
          roleCode: this.code,
          resourceGroup: it.code,
          scopeCode: it.scopeCode
        };
      });

      const params = {
        datas: result
      };
      roleBindOne(params).then(res => {
        console.log(res);
        this.$message({
          type: 'success',
          message: '绑定成功!'
        });
        this.loadList(this.string);
        this.despResources = [];
      });
    },
    //解绑方法
    unBind () {
      const params = {
        roleCode: this.code,
        resourceGroup: this.usercode
      };
      delRoleUnBindOne(params).then(res => {
        if (res.length != 0) {
          this.despResources = res;
          this.title = '请先禁用以下资源';
          this.dialogVisible = true;

          return;
        } else {

          this.$message({
            type: 'warning',
            message: '禁用成功!'
          });
          this.loadList(this.string);
        }
      });
    },
    //绑定下拉随时刷新方法
    jurisdiction (code, item) {
      this.targetResource = item;

      this.usercode = code;

      if (item.scopeCode) {
        this.roleResourceDepends();
      } else {
        this.unBind();
      }
    },
    //取消
    cancel () {
      this.dialogVisible = false;
      this.despResources = [];

      this.loadList(this.string);
    },
    //关闭
    handleClose () {
      this.dialogVisible = false;
      this.drawer = false;
      this.despResources = [];
      this.loadList(this.string);
    },
    //提交
    submit () {

      this.dialogVisible = false;
      if (this.title == '该依赖所包含的权限') {
        if (this.despResources.every(it => it.scopeCode)) {
          this.dialogVisible = false;
          this.Bind();

          return;
        } else {
          this.dialogVisible = true;
          this.$message({
            type: 'warning',
            message: '请配置所有包含的依赖!'
          });
          this.loadList(this.string);
        }
        return;
      } else {
        this.loadList(this.string);
      }
    },
    //tab栏切换
    handleClick (tab) {
      this.string = tab.name;

      this.loadList(tab.name);
    }
  }
};
</script>
<style lang="scss" scoped>
.input_felx {
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
}
.label {
  font-size: 12px;
  border: 1px solid #dcdfe6;
  margin-right: -1px;
  padding: 6px 20px;
  width: 200px;
}
.el-input--suffix .el-input__inner {
  height: 50px;
  text-align: justify;
}
</style>
